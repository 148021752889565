import { CoreModule } from '@abp/ng.core';
import { SettingManagementConfigModule } from '@abp/ng.setting-management/config';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommercialUiConfigModule } from '@volo/abp.commercial.ng.ui/config';
import { AccountAdminConfigModule } from '@volo/abp.ng.account/admin/config';
import { AccountPublicConfigModule } from '@volo/abp.ng.account/public/config';
import { AuditLoggingConfigModule } from '@volo/abp.ng.audit-logging/config';
import { OpeniddictproConfigModule } from '@volo/abp.ng.openiddictpro/config';
import { IdentityConfigModule } from '@volo/abp.ng.identity/config';
import { LanguageManagementConfigModule } from '@volo/abp.ng.language-management/config';
import { registerLocale } from '@volo/abp.ng.language-management/locale';
import { SaasConfigModule } from '@volo/abp.ng.saas/config';
import { TextTemplateManagementConfigModule } from '@volo/abp.ng.text-template-management/config';
import { ThemeLeptonModule } from '@volo/abp.ng.theme.lepton';
import { CustomHttpErrorComponent } from './shared/custom-http-error/custom-http-error.component';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PROJECTS_PROJECT_ROUTE_PROVIDER } from './projects/project-route.provider';
import { PUBLICENDPOINTS_PUBLİCENDPOINT_ROUTE_PROVIDER } from './public-endpoints/public-endpoint-route.provider';
import { PUBLISHEDMODELS_PUBLISHEDMODEL_ROUTE_PROVIDER } from './published-models/published-model-route.provider';
import { APP_ROUTE_PROVIDER } from './route.provider';
import { NgxsModule } from '@ngxs/store';
import { VersionInfoComponent } from './version-info/version-info.component';
import { SharedModule } from './shared/shared.module';
import { FooterComponent } from './shared/footer/footer.component';
import { AbpOAuthModule } from '@abp/ng.oauth';
import { CUSTOM_MENU_ITEM } from './version-info/version-info.service';

@NgModule({
  declarations: [AppComponent, VersionInfoComponent, FooterComponent, CustomHttpErrorComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    CoreModule.forRoot({
      environment,
      registerLocaleFn: registerLocale(),
    }),
    ThemeSharedModule.forRoot({
      httpErrorConfig: {
        errorScreen: {
          component: CustomHttpErrorComponent,
          forWhichErrors: [401, 403, 404, 500],
          hideCloseIcon: true,
        },
      },
    }),
    AccountAdminConfigModule.forRoot(),
    AccountPublicConfigModule.forRoot(),
    IdentityConfigModule.forRoot(),
    LanguageManagementConfigModule.forRoot(),
    SaasConfigModule.forRoot(),
    AuditLoggingConfigModule.forRoot(),
    OpeniddictproConfigModule.forRoot(),
    TextTemplateManagementConfigModule.forRoot(),
    SettingManagementConfigModule.forRoot(),
    ThemeLeptonModule.forRoot(),
    CommercialUiConfigModule.forRoot(),
    NgxsModule.forRoot([]),
    AbpOAuthModule.forRoot(),
    // GdprConfigModule.forRoot({
    //   privacyPolicyUrl: 'gdpr-cookie-consent/privacy',
    //   cookiePolicyUrl: 'gdpr-cookie-consent/cookie',
    // }),
  ],
  providers: [
    APP_ROUTE_PROVIDER,
    PROJECTS_PROJECT_ROUTE_PROVIDER,
    PUBLISHEDMODELS_PUBLISHEDMODEL_ROUTE_PROVIDER,
    PUBLICENDPOINTS_PUBLİCENDPOINT_ROUTE_PROVIDER,
    CUSTOM_MENU_ITEM,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
