import { AbpApplicationConfigurationService, ConfigStateService } from '@abp/ng.core';
import { Component } from '@angular/core';
import { HttpErrorComponent } from '@volo/abp.ng.theme.lepton';

@Component({
  selector: 'sai-custom-http-error',
  templateUrl: './custom-http-error.component.html',
})
export class CustomHttpErrorComponent extends HttpErrorComponent {
  constructor(
    private abpApplicationConfigurationService: AbpApplicationConfigurationService,
    private config: ConfigStateService,
  ) {
    super();
  }

  goHome() {
    this.abpApplicationConfigurationService
      .get({ includeLocalizationResources: true })
      .subscribe((config) => {
        this.config.setState(config);
        this.router.navigateByUrl('/', { onSameUrlNavigation: 'reload' });
        this.destroy$?.next();
      });
  }
}
